"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // This map contains reusable debug messages (only those used in multiple places)

exports.default = {
  expired: "Session expired! Please re-launch the app",
  noScopeForId: "Trying to get the ID of the selected %s. Please add 'launch' or 'launch/%s' to the requested scopes and try again.",
  noIfNoAuth: "You are trying to get %s but the app is not authorized yet.",
  noFreeContext: "Please don't use open fhir servers if you need to access launch context items like the %S."
};